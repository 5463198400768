import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollOptions = {
      top: 0,
      left: 0,
      behavior: 'auto'
    };

    // Try to scroll the main content first
    const mainContent = document.querySelector('main');
    if (mainContent) {
      mainContent.scrollTo(scrollOptions);
    }

    // Also scroll the window as a fallback
    window.scrollTo(scrollOptions);
  }, [pathname]);

  return null;
}
