import { ReactNode } from 'react';
import Widget from './Widget';

interface WidgetProviderProps {
  children: ReactNode;
}

export function WidgetProvider({ children }: WidgetProviderProps) {
  return (
    <>
      {children}
      <Widget />
    </>
  );
}
