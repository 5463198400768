// Google Analytics Measurement ID
const GA_MEASUREMENT_ID = 'G-8EZ3QKG11C';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const initializeGA = () => {
  // Load the Google Analytics script
  const policy = trustedTypes.createPolicy('default', {
    createScriptURL: (url) => url
  });
  
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = policy.createScriptURL(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`);
  document.head.appendChild(script1);

  // Initialize the dataLayer
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;

  gtag('js', new Date());
  gtag('config', GA_MEASUREMENT_ID);
};

export const trackPageView = (url: string) => {
  if (window.gtag) {
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_path: url,
    });
  }
};

export const trackEvent = (
  action: string,
  category: string,
  label?: string,
  value?: number
) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};
