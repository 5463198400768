import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Dashboard from '../components/Dashboard';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Calculato.co | Free Online Calculators for Every Need</title>
        <meta name="description" content="Your comprehensive online calculator suite with 50+ free tools. Access financial, scientific, health, and utility calculators with real-time results. No installation required, mobile-friendly, and completely free to use." />
        <meta name="keywords" content="calculator, online calculator, free calculator, math calculator, financial calculator, scientific calculator, calculator suite, web calculator, calculator tools, calculato, calculation tools, BMI calculator, mortgage calculator, investment calculator" />
        <link rel="canonical" href="https://calculato.co/" />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Dashboard />
      </Suspense>
    </>
  );
}
