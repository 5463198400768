import React from 'react';

interface EmbedLayoutProps {
  children: React.ReactNode;
}

export default function EmbedLayout({ children }: EmbedLayoutProps) {
  return (
    <div className="p-4 max-w-[800px] mx-auto">
      <div className="mb-2">
        {children}
      </div>
      <div className="text-center text-sm text-muted-foreground -mt-2">
        <a 
          href="https://calculato.co"
          className="inline-block hover:text-primary transition-colors"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center justify-center space-x-2 overflow-visible">
            <span style={{ marginTop: '1px' }}>Powered by</span>
            <div className="overflow-visible">
              <div className="relative" style={{ width: '110px', marginTop: '-11px', marginLeft: '-13px' }}>
                <img 
                  src="/logo-with-text.svg" 
                  alt="Calculato" 
                  className="absolute left-0 h-7"
                  style={{ minWidth: '110px' }}
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}