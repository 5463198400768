import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface LanguageProviderProps {
  children: React.ReactNode;
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  const { i18n } = useTranslation();
  const { lang } = useParams<{ lang?: string }>();

  useEffect(() => {
    const normalizedLang = lang ? lang.toLowerCase() === 'pt-br' ? 'pt-BR' : lang : 'en';
    if (i18n.language !== normalizedLang) {
      if (i18n.languages.includes(normalizedLang)) {
        i18n.changeLanguage(normalizedLang);
      } else {
        i18n.changeLanguage('en');
      }
    }
  }, [lang, i18n]);

  return <>{children}</>;
}
