import { create } from 'zustand';

interface WidgetStore {
  isExpanded: boolean;
  showInfo: boolean;
  activeCalculator: string | null;
  isVisible: boolean;
  lastCalculation: {
    expression: string;
    result: string;
  } | null;
  toggleExpanded: () => void;
  toggleInfo: (value?: boolean) => void;
  setActiveCalculator: (calculator: string | null) => void;
  toggleVisibility: (value?: boolean) => void;
  setLastCalculation: (calculation: { expression: string; result: string }) => void;
}

export const useWidgetStore = create<WidgetStore>((set) => ({
  isExpanded: false,
  showInfo: false,
  activeCalculator: null,
  isVisible: true,
  lastCalculation: null,
  toggleExpanded: () => set((state) => ({ isExpanded: !state.isExpanded })),
  toggleInfo: (value?: boolean) => set((state) => {
    const newShowInfo = value ?? !state.showInfo;
    console.log('showInfo state toggled:', newShowInfo);
    return { showInfo: newShowInfo };
  }),
  setActiveCalculator: (calculator) => set({ activeCalculator: calculator }),
  toggleVisibility: (value?: boolean) => set((state) => ({ isVisible: value ?? !state.isVisible })),
  setLastCalculation: (calculation) => set({ lastCalculation: calculation })
}));
