import React from 'react';
import { Users, Calculator, Clock, Award, Star, Shield } from 'lucide-react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const StatCard = ({ 
  icon: Icon, 
  value, 
  label, 
  gradient, 
  iconGradient 
}: { 
  icon: React.ElementType; 
  value: number; 
  label: string; 
  gradient: string; 
  iconGradient: string; 
}) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <li 
      className="relative group"
      ref={ref}
    >
      <div 
        className={`absolute -inset-0.5 bg-gradient-to-r ${gradient} rounded-2xl blur opacity-25 group-hover:opacity-75 transition duration-200`}
        aria-hidden="true"
      ></div>
      <div className="relative px-6 py-4 bg-white/10 backdrop-blur-sm rounded-2xl border border-white/10">
        <div className="flex flex-col items-center text-center space-y-3">
          <div 
            className={`inline-flex p-2.5 rounded-xl bg-gradient-to-br ${iconGradient}`}
            aria-hidden="true"
          >
            <Icon className="h-5 w-5 text-white" />
          </div>
          <div>
            <h3 className="text-3xl font-bold text-white">
              {inView && <CountUp end={value} duration={2} separator="," />}
              {!inView && '0'}+
            </h3>
            <p className="text-sm text-blue-200">{label}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default function TrustSection() {
  const stats = [
    {
      icon: Calculator,
      value: 50,
      label: 'Calculators',
      gradient: 'from-blue-500 to-indigo-500',
      iconGradient: 'from-blue-500 to-indigo-500'
    },
    {
      icon: Clock,
      value: 1000000,
      label: 'Calculations Made',
      gradient: 'from-indigo-500 to-purple-500',
      iconGradient: 'from-indigo-500 to-purple-500'
    },
    {
      icon: Users,
      value: 100000,
      label: 'Happy Users',
      gradient: 'from-purple-500 to-pink-500',
      iconGradient: 'from-purple-500 to-pink-500'
    }
  ];

  return (
    <section 
      className="relative"
      aria-label="Trust and Statistics Section"
    >
      {/* Background with gradient and pattern */}
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 via-indigo-600 to-purple-600 dark:from-blue-800 dark:via-indigo-800 dark:to-purple-800" />
        <div className="absolute inset-0 opacity-10 bg-[radial-gradient(#ffffff_1px,transparent_1px)] [background-size:16px_16px]" />
      </div>

      {/* Floating Elements */}
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        <div className="absolute -left-4 top-1/4 w-24 h-24 bg-blue-400/10 rounded-full blur-2xl animate-pulse" />
        <div className="absolute right-1/4 top-1/3 w-32 h-32 bg-purple-400/10 rounded-full blur-2xl animate-pulse delay-1000" />
        <div className="absolute left-1/3 bottom-1/4 w-28 h-28 bg-indigo-400/10 rounded-full blur-2xl animate-pulse delay-2000" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <header className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Trusted by users worldwide
          </h2>
          <p className="block mt-2 text-lg font-medium text-white/90">
            Join thousands who rely on our calculators daily
          </p>
        </header>

        {/* Stats Grid */}
        <div className="mt-16">
          <ul 
            className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
            role="list"
            aria-label="Statistics"
          >
            {stats.map((stat) => (
              <StatCard key={stat.label} {...stat} />
            ))}
          </ul>
        </div>

        {/* Features Grid */}
        <div className="mt-24">
          <ul 
            className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
            role="list"
            aria-label="Key Features"
          >
            <li>
              <article className="flex flex-col items-center text-center space-y-3 text-white">
                <div className="flex-shrink-0" aria-hidden="true">
                  <Star className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">High Precision</h3>
                  <p className="mt-1 opacity-80">Accurate results you can trust</p>
                </div>
              </article>
            </li>

            <li>
              <article className="flex flex-col items-center text-center space-y-3 text-white">
                <div className="flex-shrink-0" aria-hidden="true">
                  <Shield className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Privacy First</h3>
                  <p className="mt-1 opacity-80">No data collection or tracking</p>
                </div>
              </article>
            </li>

            <li>
              <article className="flex flex-col items-center text-center space-y-3 text-white">
                <div className="flex-shrink-0" aria-hidden="true">
                  <Award className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Free Forever</h3>
                  <p className="mt-1 opacity-80">No hidden fees or subscriptions</p>
                </div>
              </article>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}