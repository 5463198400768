import React from 'react';
import { Zap, Cloud, Share2, Smartphone, Download, History, Lock, PenTool, Clock, Palette } from 'lucide-react';

const features = [
  {
    icon: Zap,
    title: 'Lightning Fast',
    description: 'Instant calculations with real-time updates as you type',
    gradient: 'from-yellow-500 to-orange-400'
  },
  {
    icon: Cloud,
    title: 'No Installation',
    description: 'Access all calculators directly in your browser online',
    gradient: 'from-sky-500 to-indigo-500'
  },
  {
    icon: Share2,
    title: 'Easy Sharing',
    description: 'Share calculations and results with anyone instantly',
    gradient: 'from-green-500 to-emerald-400'
  },
  {
    icon: Smartphone,
    title: 'Mobile Friendly',
    description: 'Perfect experience on any device, anywhere, anytime',
    gradient: 'from-violet-500 to-purple-400'
  },
  {
    icon: Download,
    title: 'Export Results',
    description: 'Download your calculations in multiple formats',
    gradient: 'from-blue-500 to-cyan-400'
  },
  {
    icon: History,
    title: 'Calculation History',
    description: 'Track and review your previous calculations',
    gradient: 'from-rose-500 to-pink-400'
  },
  {
    icon: Lock,
    title: 'Privacy First',
    description: 'Your calculations stay in your browser',
    gradient: 'from-teal-500 to-emerald-400'
  },
  {
    icon: PenTool,
    title: 'Precise Results',
    description: 'High-precision calculations you can trust',
    gradient: 'from-amber-500 to-red-400'
  },
  {
    icon: Clock,
    title: 'Time Saving',
    description: 'Get results faster than traditional methods',
    gradient: 'from-indigo-500 to-fuchsia-400'
  },
  {
    icon: Palette,
    title: 'Embed Anywhere',
    description: 'Add any calculator to your website with a simple embed code',
    gradient: 'from-lime-500 to-green-400'
  }
];

const FeatureCard = ({ icon: Icon, title, description, gradient }: {
  icon: React.ElementType;
  title: string;
  description: string;
  gradient: string;
}) => {
  return (
    <article className="relative group h-full">
      <div 
        className="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-lg blur opacity-25 group-hover:opacity-40 transition duration-200"
        aria-hidden="true"
      ></div>
      <div className="relative h-full p-4 sm:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg transform transition duration-200 hover:-translate-y-1 hover:shadow-xl flex flex-col">
        <div className="relative flex items-center mb-3 sm:mb-4">
          <div className={`absolute h-10 sm:h-12 left-0 right-0 bg-gradient-to-r ${gradient} rounded-lg opacity-90`} />
          <div className="relative z-10 w-10 h-10 sm:w-12 sm:h-12 rounded-lg p-2 sm:p-2.5 group-hover:scale-110 transition duration-200 flex-shrink-0">
            <Icon className="w-full h-full text-white" strokeWidth={1.5} />
          </div>
        </div>
        <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-1 sm:mb-2 flex-shrink-0">
          {title}
        </h3>
        <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-300">
          {description}
        </p>
      </div>
    </article>
  );
};

export default function FeaturesSection() {
  return (
    <section 
      className="py-12 sm:py-20 bg-gray-50 dark:bg-gray-800"
      aria-label="Features Section"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="text-center mb-8 sm:mb-12">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-3 sm:mb-4">
             Unlock Advanced Calculations with Ease
          </h2>
          <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300">
            Powerful features designed to make your calculations easier, faster and more efficient
          </p>
        </header>
        
        <ul 
          className="grid grid-cols-2 gap-3 sm:gap-4 md:gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5"
          role="list"
          aria-label="Calculator features"
        >
          {features.map((feature, index) => (
            <li key={index}>
              <FeatureCard {...feature} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}