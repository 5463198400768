import { BookOpen, Calculator, Brain, Activity, Scale, Heart } from 'lucide-react';
import { bodyFatCalculator } from './calculatorInfo/body-fat';
import { calorieCalculator } from './calculatorInfo/calorie';
import { basicCalculator } from './calculatorInfo/basicCalculator';

export interface FAQ {
  question: string;
  answer: string;
}

export interface Formula {
  expression: string;
  explanation: string;
  variables: {
    name: string;
    description: string;
  }[];
}

export interface CalculatorInfo {
  id: string;
  title: string;
  description: string;
  icon: any;
  formulas: Formula[];
  faqs?: FAQ[];
  references?: string[];
}

export const calculatorInfoMap: Record<string, CalculatorInfo> = {
  'bmi-calculator': {
    id: 'bmi-calculator',
    title: 'BMI Calculator',
    description: 'Calculate your Body Mass Index (BMI), a measure of body fat based on height and weight that applies to adult men and women.',
    icon: Scale,
    formulas: [
      {
        expression: 'BMI = weight (kg) / height² (m²)',
        explanation: 'Body Mass Index is calculated by dividing weight in kilograms by height in meters squared.',
        variables: [
          { name: 'weight', description: 'Weight in kilograms (kg)' },
          { name: 'height', description: 'Height in meters (m)' }
        ]
      }
    ],
    faqs: [
      {
        question: 'What is a healthy BMI range?',
        answer: 'A BMI between 18.5 and 24.9 is considered healthy for most adults. Below 18.5 is underweight, 25-29.9 is overweight, and 30 or above is obese.'
      },
      {
        question: 'Is BMI accurate for everyone?',
        answer: 'BMI is not a perfect measure as it does not account for factors like muscle mass, bone density, age, and sex. Athletes or muscular individuals may have a high BMI but not be overweight.'
      }
    ],
    references: [
      'World Health Organization BMI Classification',
      'National Institutes of Health BMI Guidelines'
    ]
  },
  'body-fat-calculator': bodyFatCalculator,
  'calorie-calculator': calorieCalculator,
  'basic-calculator': basicCalculator,
};
