import { motion, AnimatePresence } from 'framer-motion';
import { X, BookOpen, Calculator, HelpCircle, Link as LinkIcon } from 'lucide-react';
import { getCalculatorMetadata } from '../../lib/calculatorRegistry';
import { useState } from 'react';

interface InfoModalProps {
  calculatorId: string;
  isOpen: boolean;
  onClose: () => void;
  isMobile?: boolean;
}

export default function InfoModal({ calculatorId, isOpen, onClose, isMobile = false }: InfoModalProps) {
  const [activeTab, setActiveTab] = useState<'description' | 'formulas' | 'faqs'>('description');
  
  // Get calculator info using the same registry as other components
  const calculatorInfo = getCalculatorMetadata(calculatorId);

  // Debug logs only in development
  if (process.env.NODE_ENV === 'development') {
    console.log('Calculator ID:', calculatorId);
    console.log('Normalized ID:', calculatorId?.toLowerCase().trim());
    console.log('Calculator Info:', calculatorInfo);
  }

  // Don't render anything if modal is not open
  if (!isOpen) return null;

  // Handle missing calculator info
  if (!calculatorInfo) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50"
      >
        <div className="bg-gray-900 rounded-xl p-6 max-w-lg w-full">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">Information Unavailable</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="text-gray-300">
            Sorry, information for this calculator is not available at the moment.
          </p>
        </div>
      </motion.div>
    );
  }

  const TabButton = ({ tab, label, icon: Icon }: { tab: typeof activeTab, label: string, icon: any }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`
        flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
        transition-colors
        ${activeTab === tab 
          ? 'bg-violet-500 text-white' 
          : 'text-gray-400 hover:text-gray-100 hover:bg-white/5'
        }
      `}
    >
      <Icon size={16} />
      {label}
    </button>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'description':
        return (
          <div className="space-y-4">
            <div className="flex items-center gap-3 text-white">
              {calculatorInfo.icon && <calculatorInfo.icon size={24} className="text-violet-400" />}
              <h2 className="text-xl font-semibold">{calculatorInfo.title}</h2>
            </div>
            <p className="text-gray-300 leading-relaxed">
              {calculatorInfo.description}
            </p>
          </div>
        );

      case 'formulas':
        if (!calculatorInfo.formulas?.length) {
          return (
            <div className="text-gray-400 text-center py-4">
              No formulas available for this calculator.
            </div>
          );
        }
        return (
          <div className="space-y-6">
            {calculatorInfo.formulas.map((formula, index) => (
              <div key={index} className="space-y-3">
                <div className="bg-gray-800/50 rounded-lg p-4">
                  <div className="text-lg font-mono text-violet-300 mb-2">
                    {formula.expression}
                  </div>
                  <p className="text-gray-300">{formula.explanation}</p>
                </div>
                <div className="space-y-2">
                  {formula.variables?.map((variable, vIndex) => (
                    <div key={vIndex} className="flex items-start gap-2 text-sm">
                      <span className="text-violet-400 font-mono">{variable.name}</span>
                      <span className="text-gray-400">-</span>
                      <span className="text-gray-300">{variable.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );

      case 'faqs':
        return (
          <div className="space-y-4">
            {calculatorInfo.faqs?.map((faq, index) => (
              <div key={index} className="space-y-2">
                <h3 className="text-white font-medium">{faq.question}</h3>
                <p className="text-gray-300 text-sm leading-relaxed">
                  {faq.answer}
                </p>
              </div>
            ))}
          </div>
        );
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        className={`
          fixed z-50 bg-gray-900 backdrop-blur-lg rounded-2xl
          shadow-xl border border-white/10 w-[380px] overflow-hidden
          ${isMobile 
            ? 'bottom-32 right-4 max-w-[calc(95vw-32px)] h-[57vh]' 
            : 'top-1/4 right-20 h-[50vh]'}
        `}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b border-white/10 bg-gray-900 sticky top-0 z-10">
            <div className="flex gap-2">
              <TabButton tab="description" label="About" icon={BookOpen} />
              <TabButton tab="formulas" label="Formulas" icon={Calculator} />
              <TabButton tab="faqs" label="FAQs" icon={HelpCircle} />
            </div>
            <button 
              onClick={onClose}
              className="text-white/60 hover:text-white/90 transition-colors"
            >
              <X size={20} />
            </button>
          </div>

          {/* Content */}
          <div className="flex-1 overflow-y-auto min-h-0 relative">
            <div className="absolute inset-0 overflow-y-auto">
              <div className="p-4">
                {renderContent()}
              </div>
            </div>
          </div>

          {/* Footer with References */}
          {calculatorInfo.references && (
            <div className="p-4 border-t border-white/10 bg-gray-900 sticky bottom-0 z-10">
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <LinkIcon size={14} />
                <span>References:</span>
              </div>
              <ul className="mt-2 space-y-1">
                {calculatorInfo.references.map((ref, index) => (
                  <li key={index} className="text-sm">
                    <a 
                      href={ref.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-violet-400 hover:text-violet-300 cursor-pointer"
                    >
                      {ref.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
