import React, { useState } from 'react';
import { initializeGA } from '../utils/analytics';

const ConsentPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(!localStorage.getItem('consentGiven'));

  const handleAccept = () => {
    localStorage.setItem('consentGiven', 'true');
    setIsVisible(false);
    // Initialize Google Analytics or other tracking scripts
    initializeGA();
  };

  const handleDecline = () => {
    localStorage.setItem('consentGiven', 'false');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-900 text-black dark:text-white p-4 z-50 shadow-lg">
      <div className="max-w-4xl mx-auto flex justify-between items-center">
        <p className="text-sm">
          We use cookies to improve your experience. By using our site, you agree to our use of cookies. Read our <a href="/privacy-policy" className="underline">Privacy Policy</a>.
        </p>
        <div>
          <button onClick={handleAccept} className="bg-green-700 hover:bg-green-800 text-white px-4 py-2 m-2 rounded text-sm">Accept</button>
          <button onClick={handleDecline} className="bg-red-700 hover:bg-red-800 text-white px-4 py-2 m-2 rounded text-sm">Decline</button>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;
