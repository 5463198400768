import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  DollarSign, 
  Heart, 
  Scale,
  Calculator,
  Clock,
  Calendar,
  Wallet,
  Brain,
  Percent,
  LineChart,
  Building2,
  Home,
  Ruler,
  ArrowLeftRight,
  Binary,
  Hash,
  Shuffle,
  Key,
  FileText
} from 'lucide-react';

// Popular Tools (Limited to 9)
const popularTools = [
  {
    name: 'Mortgage Calculator',
    description: 'Calculate mortgage payments and amortization schedules',
    icon: DollarSign,
    category: 'Popular',
    path: '/mortgage-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'BMI Calculator',
    description: 'Calculate your Body Mass Index',
    icon: Scale,
    category: 'Popular',
    path: '/bmi-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'TDEE Calculator',
    description: 'Calculate your Total Daily Energy Expenditure',
    icon: Heart,
    category: 'Popular',
    path: '/tdee-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'Loan Calculator',
    description: 'Calculate loan payments and terms',
    icon: Wallet,
    category: 'Popular',
    path: '/loan-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Scientific Calculator',
    description: 'Advanced mathematical calculations',
    icon: Brain,
    category: 'Popular',
    path: '/scientific-calculator',
    theme: 'bg-purple-100 text-purple-600'
  },
  {
    name: 'Percentage Calculator',
    description: 'Calculate percentages easily',
    icon: Percent,
    category: 'Popular',
    path: '/percentage-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Time Calculator',
    description: 'Add or subtract hours, minutes, and seconds',
    icon: Clock,
    category: 'Popular',
    path: '/time-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Age Calculator',
    description: 'Calculate age between two dates',
    icon: Calendar,
    category: 'Popular',
    path: '/age-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Tax Calculator',
    description: 'Estimate your tax payments',
    icon: DollarSign,
    category: 'Popular',
    path: '/tax-calculator',
    theme: 'bg-blue-100 text-blue-600'
  }
];

// Category-specific calculators
const financialTools = [
  {
    name: 'Mortgage Calculator',
    description: 'Calculate mortgage payments',
    icon: DollarSign,
    category: 'Financial',
    path: '/mortgage-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Loan Calculator',
    description: 'Calculate loan payments and terms',
    icon: Wallet,
    category: 'Financial',
    path: '/loan-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Investment Calculator',
    description: 'Plan your investment growth',
    icon: DollarSign,
    category: 'Financial',
    path: '/investment-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Compound Interest',
    description: 'Calculate compound interest growth',
    icon: DollarSign,
    category: 'Financial',
    path: '/compound-interest-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Auto Loan Calculator',
    description: 'Calculate auto loan payments',
    icon: DollarSign,
    category: 'Financial',
    path: '/auto-loan-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Tax Calculator',
    description: 'Estimate your tax payments',
    icon: Wallet,
    category: 'Financial',
    path: '/tax-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Retirement Calculator',
    description: 'Plan your retirement savings',
    icon: Wallet,
    category: 'Financial',
    path: '/retirement-calculator',
    theme: 'bg-blue-100 text-blue-600'
  },
  {
    name: 'Savings Calculator',
    description: 'Track your savings growth',
    icon: DollarSign,
    category: 'Financial',
    path: '/savings-calculator',
    theme: 'bg-blue-100 text-blue-600'
  }
];
const healthTools = [
  {
    name: 'BMI Calculator',
    description: 'Calculate your Body Mass Index',
    icon: Scale,
    category: 'Health',
    path: '/bmi-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'TDEE Calculator',
    description: 'Calculate Total Daily Energy Expenditure',
    icon: Heart,
    category: 'Health',
    path: '/tdee-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'BMR Calculator',
    description: 'Calculate Basal Metabolic Rate',
    icon: Heart,
    category: 'Health',
    path: '/bmr-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'Body Fat Calculator',
    description: 'Estimate your body fat percentage',
    icon: Scale,
    category: 'Health',
    path: '/body-fat-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'Calorie Calculator',
    description: 'Calculate daily calorie needs',
    icon: Heart,
    category: 'Health',
    path: '/calorie-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'Protein Calculator',
    description: 'Calculate daily protein needs',
    icon: Heart,
    category: 'Health',
    path: '/protein-calculator',
    theme: 'bg-pink-100 text-pink-600'
  },
  {
    name: 'Pace Calculator',
    description: 'Calculate running/walking pace',
    icon: Heart,
    category: 'Health',
    path: '/pace-calculator',
    theme: 'bg-pink-100 text-pink-600'
  }
];

const academicTools = [
  {
    name: 'Scientific Calculator',
    description: 'Advanced scientific calculations',
    icon: Brain,
    category: 'Academic',
    path: '/scientific-calculator',
    theme: 'bg-purple-100 text-purple-600'
  },
  {
    name: 'Matrix Calculator',
    description: 'Perform matrix operations',
    icon: Brain,
    category: 'Academic',
    path: '/matrix-calculator',
    theme: 'bg-purple-100 text-purple-600'
  },
  {
    name: 'Algebra Calculator',
    description: 'Solve algebraic equations',
    icon: Brain,
    category: 'Academic',
    path: '/algebra-calculator',
    theme: 'bg-purple-100 text-purple-600'
  },
  {
    name: 'Standard Deviation',
    description: 'Calculate standard deviation',
    icon: Brain,
    category: 'Academic',
    path: '/standard-deviation-calculator',
    theme: 'bg-purple-100 text-purple-600'
  }
];

const lifestyleTools = [
  {
    name: 'Time Calculator',
    description: 'Calculate time differences',
    icon: Clock,
    category: 'Lifestyle',
    path: '/time-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Age Calculator',
    description: 'Calculate exact age from birth date',
    icon: Calendar,
    category: 'Lifestyle',
    path: '/age-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Percentage Calculator',
    description: 'Calculate percentages easily',
    icon: Percent,
    category: 'Lifestyle',
    path: '/percentage-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Tip Calculator',
    description: 'Calculate tips and split bills',
    icon: DollarSign,
    category: 'Lifestyle',
    path: '/tip-calculator',
    theme: 'bg-orange-100 text-orange-600'
  },
  {
    name: 'Date Calculator',
    description: 'Calculate dates and date ranges',
    icon: Calendar,
    category: 'Lifestyle',
    path: '/date-calculator',
    theme: 'bg-orange-100 text-orange-600'
  }
];

const categories = [
  { name: 'Popular', id: 'popular' },
  { name: 'Financial', id: 'financial' },
  { name: 'Health', id: 'health' },
  { name: 'Academic', id: 'academic' },
  { name: 'Lifestyle', id: 'lifestyle' }
];

export default function PopularCalculators() {
  const [activeCategory, setActiveCategory] = useState('popular');

  const getToolsByCategory = (category: string) => {
    switch (category) {
      case 'popular':
        return popularTools;
      case 'financial':
        return financialTools;
      case 'health':
        return healthTools;
      case 'academic':
        return academicTools;
      case 'lifestyle':
        return lifestyleTools;
      default:
        return popularTools;
    }
  };

  return (
    <div className="py-16 bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Popular Calculators
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Quick access to our most used calculators
          </p>
        </div>

        <div className="flex justify-center gap-4 mb-8 flex-wrap">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                ${activeCategory === category.id
                  ? 'bg-violet-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                }`}
            >
              {category.name}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {getToolsByCategory(activeCategory).map((tool) => {
            const Icon = tool.icon;
            return (
              <Link
                key={tool.path}
                to={tool.path}
                className="group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700"
              >
                <div className={`inline-flex p-3 rounded-xl ${tool.theme} transform group-hover:scale-110 transition-transform duration-200`}>
                  <Icon className="h-6 w-6" />
                </div>
                <h3 className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-100">
                  {tool.name}
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {tool.description}
                </p>
              </Link>
            );
          })}
        </div>
        
        {/* View All Calculators Button */}
        <div className="text-center mt-12">
          <Link
            to="/calculators"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg shadow-sm text-white bg-violet-600 hover:bg-violet-700 transition-colors duration-200"
          >
            View All Calculators
            <Calculator className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}