import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Share2, Info, Calculator, Code, X } from 'lucide-react';
import { useWidgetStore } from '../../lib/store';
import { calculatorInfoMap } from '../../lib/calculatorInfo';
import QuickCalculator from './QuickCalculator';
import InfoModal from './InfoModal';
import { useState, useEffect } from 'react';

export default function Widget() {
  const { isExpanded, toggleExpanded, activeCalculator, toggleInfo, showInfo, isVisible, toggleVisibility } = useWidgetStore();
  const calculatorInfo = activeCalculator ? calculatorInfoMap[activeCalculator] : null;
  const [isQuickCalcOpen, setIsQuickCalcOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const hiddenPaths = [
    '/', 
    '/home', 
    '/about', 
    '/contact', 
    '/terms-of-use', 
    '/privacy-policy',
    '/financial-calculators',
    '/health-calculators',
    '/academic-calculators',
    '/lifestyle-calculators',
    '/advanced-calculators',
    '/utility-calculators',
    '/categories',
    '/calculators'
  ];
  const [shouldShow, setShouldShow] = useState(!hiddenPaths.includes(currentPath));

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Monitor URL changes
  useEffect(() => {
    const handleUrlChange = () => {
      const newPath = window.location.pathname;
      if (newPath !== currentPath) {
        setCurrentPath(newPath);
        setShouldShow(!hiddenPaths.includes(newPath));
        try {
          // Close the widget if it's expanded
          if (isExpanded) {
            toggleExpanded();
          }
          // Close the quick calculator if it's open
          if (isQuickCalcOpen) {
            setIsQuickCalcOpen(false);
          }
          // Close the info modal if it's open
          if (showInfo) {
            toggleInfo(false);
          }
        } catch (error) {
          console.error('Error while closing widget on page change:', error);
          setIsQuickCalcOpen(false);
          toggleInfo(false);
        }
      }
    };

    // Listen for both popstate and pushstate events
    window.addEventListener('popstate', handleUrlChange);
    
    // Create a custom event for handling pushState
    const originalPushState = window.history.pushState;
    window.history.pushState = function() {
      originalPushState.apply(this, arguments);
      handleUrlChange();
    };

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.history.pushState = originalPushState;
    };
  }, [currentPath, isExpanded, isQuickCalcOpen, showInfo]);

  // Close quick calculator when widget is collapsed
  useEffect(() => {
    if (!isExpanded) {
      setIsQuickCalcOpen(false);
    }
  }, [isExpanded]);

  if (!shouldShow) {
    return null;
  }

  // Only render InfoModal when we have an active calculator
  const shouldShowInfo = showInfo && activeCalculator;

  const iconSize = 20;
  const buttonClass = "w-8 h-8 rounded-full flex items-center justify-center hover:bg-white/10 transition-colors";

  return (
    <>
      {/* Info Modal */}
      {activeCalculator && (
        <InfoModal
          calculatorId={activeCalculator}
          isOpen={shouldShowInfo}
          onClose={() => toggleInfo(false)}
          isMobile={isMobile}
        />
      )}

      {/* Quick Calculator Modal */}
      {isQuickCalcOpen && (
        <QuickCalculator 
          isOpen={isQuickCalcOpen} 
          onClose={() => setIsQuickCalcOpen(false)} 
          isMobile={isMobile}
        />
      )}
      
      {/* Reopen Button - Desktop Only */}
      {!isMobile && !isVisible && (
        <motion.button
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
          onClick={() => toggleVisibility(true)}
          className="fixed z-50 top-[20%] -right-0 w-6 h-24
            bg-gradient-to-b from-violet-600 to-fuchsia-600
            flex items-center justify-center
            text-white/90 hover:text-white transition-colors
            rounded-l-lg shadow-lg"
          style={{
            boxShadow: '0 4px 15px rgba(124, 58, 237, 0.3)',
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            transform: 'rotate(180deg)'
          }}
          aria-label="Show calculator widget"
        >
          <ChevronLeft size={20} />
        </motion.button>
      )}
      
      <motion.div
        className={`
          fixed z-50 select-none
          ${isMobile ? 'bottom-16 -right-1' : 'top-[20%] right-4'}
          ${!isVisible && !isMobile ? 'translate-x-[calc(100%+16px)]' : ''}
        `}
        animate={{ 
          x: !isVisible && !isMobile ? 'calc(100% + 16px)' : 0,
          transition: { type: "spring", stiffness: 500, damping: 30 }
        }}
      >
        <div className={`flex ${isMobile ? 'flex-row' : 'flex-col'}`}>
          <div 
            className={`
              shadow-lg backdrop-blur-sm
              transition-all duration-300 ease-in-out
              bg-gradient-to-r from-violet-600 to-fuchsia-600
              flex ${isMobile ? 'items-center' : 'flex-col items-center'}
              ${isMobile 
                ? (isExpanded ? 'rounded-l-full pl-3 pr-4' : 'w-6 rounded-l-full hover:w-8') 
                : 'rounded-2xl p-2 gap-2'}
            `}
            style={{
              boxShadow: '0 4px 15px rgba(124, 58, 237, 0.3)',
              height: isMobile ? '48px' : 'auto',
              width: isMobile ? 'auto' : '48px'
            }}
          >
            {/* Toggle Button - Mobile Only */}
            {isMobile && (
              <button 
                onClick={toggleExpanded}
                className="h-8 flex items-center justify-center text-white/90 hover:text-white"
                aria-label={isExpanded ? "Collapse calculator widget" : "Expand calculator widget"}
              >
                {isExpanded ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
              </button>
            )}

            {/* Action Buttons */}
            <motion.div 
              animate={isMobile ? {
                width: isExpanded ? 'auto' : 0,
                opacity: isExpanded ? 1 : 0
              } : {}}
              className={`
                flex ${isMobile ? 'flex-row' : 'flex-col'}
                items-center gap-2 
                ${isMobile ? 'overflow-hidden' : ''}
              `}
            >
              <button 
                className={buttonClass + " text-white"}
                aria-label={calculatorInfo ? `About ${calculatorInfo.title}` : 'Calculator information'}
                onClick={() => {
                  console.log('Info button clicked, toggling info modal');
                  toggleInfo();
                }}
              >
                <Info size={iconSize} />
              </button>

              <button 
                className={buttonClass}
                aria-label="Open quick calculator"
                onClick={() => setIsQuickCalcOpen(true)}
              >
                <Calculator size={iconSize} />
              </button>

              <button 
                className={buttonClass}
                aria-label="Share calculator"
                onClick={() => {
                  if (calculatorInfo) {
                    navigator.share({
                      title: calculatorInfo.title,
                      text: calculatorInfo.description,
                      url: window.location.href
                    }).catch(console.error);
                  }
                }}
              >
                <Share2 size={iconSize} />
              </button>

              <button 
                className={buttonClass + " text-white"}
                title="Embed Calculator"
              >
                <Code size={iconSize} />
              </button>

              {/* Close Button - Desktop Only */}
              {!isMobile && (
                <button 
                  onClick={() => toggleVisibility()}
                  className={buttonClass + " text-white"}
                  aria-label={isVisible ? "Hide calculator widget" : "Show calculator widget"}
                >
                  <ChevronRight 
                    size={iconSize}
                  />
                </button>
              )}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
