import React, { Suspense } from 'react';
import CategoryGrid from './DashboardModular/CategoryGrid';
import HeroSection from './DashboardModular/HeroSection';
import PopularCalculators from './DashboardModular/PopularCalculators';
import FeaturesSection from './DashboardModular/FeaturesSection';
import TrustSection from './DashboardModular/TrustSection';
import FootnotesSection from './DashboardModular/FootnotesSection';

export default function Dashboard() {
  return (
    <div className="min-h-screen dark:bg-gray-900">
      <Suspense fallback={<div>Loading...</div>}>
        <HeroSection />
        <CategoryGrid />
        <PopularCalculators />
        <FeaturesSection />
        <TrustSection />
        <FootnotesSection />
      </Suspense>
    </div>
  );
}