import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useState } from 'react';
import { useWidgetStore } from '../../lib/store';

interface QuickCalculatorProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile?: boolean;
}

export default function QuickCalculator({ isOpen, onClose, isMobile = false }: QuickCalculatorProps) {
  const [display, setDisplay] = useState('0');
  const [prevValue, setPrevValue] = useState<number | null>(null);
  const [operator, setOperator] = useState<string | null>(null);
  const [shouldResetDisplay, setShouldResetDisplay] = useState(false);
  const setLastCalculation = useWidgetStore((state) => state.setLastCalculation);

  const handleNumber = (num: string) => {
    if (display === '0' || shouldResetDisplay) {
      setDisplay(num);
      setShouldResetDisplay(false);
    } else {
      setDisplay(display + num);
    }
  };

  const handleOperator = (op: string) => {
    const currentValue = parseFloat(display);
    
    if (prevValue === null) {
      setPrevValue(currentValue);
    } else if (operator) {
      const result = calculate(prevValue, currentValue, operator);
      setPrevValue(result);
      setDisplay(result.toString());
      setLastCalculation({
        expression: `${prevValue} ${operator} ${currentValue}`,
        result: result.toString()
      });
    }
    
    setOperator(op);
    setShouldResetDisplay(true);
  };

  const calculate = (a: number, b: number, op: string): number => {
    switch (op) {
      case '+': return a + b;
      case '-': return a - b;
      case '×': return a * b;
      case '÷': return b !== 0 ? a / b : 0;
      default: return b;
    }
  };

  const handleEqual = () => {
    if (prevValue === null || operator === null) return;
    
    const currentValue = parseFloat(display);
    const result = calculate(prevValue, currentValue, operator);
    
    setDisplay(result.toString());
    setLastCalculation({
      expression: `${prevValue} ${operator} ${currentValue}`,
      result: result.toString()
    });
    setPrevValue(null);
    setOperator(null);
    setShouldResetDisplay(true);
  };

  const handleClear = () => {
    setDisplay('0');
    setPrevValue(null);
    setOperator(null);
    setShouldResetDisplay(false);
  };

  const buttonClass = "w-12 h-12 rounded-full bg-white/10 hover:bg-white/20 transition-colors flex items-center justify-center text-white text-xl font-medium";

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
          className={`
            fixed z-50 bg-gray-900/95 backdrop-blur-lg rounded-2xl p-4
            shadow-xl border border-white/10
            ${isMobile ? 'bottom-32 right-4' : 'top-1/4 right-20'}
          `}
          style={{ width: '280px' }}
        >
          <div className="flex justify-between items-center mb-4">
            <div className="text-white/60 text-sm font-medium">Quick Calculator</div>
            <button 
              onClick={onClose}
              className="text-white/60 hover:text-white/90 transition-colors"
            >
              <X size={20} />
            </button>
          </div>

          <div className="bg-white/5 rounded-xl p-3 mb-4">
            <div className="text-right text-white text-3xl font-light truncate">
              {display}
            </div>
          </div>

          <div className="grid grid-cols-4 gap-2">
            <button onClick={handleClear} className={`${buttonClass} col-span-2 bg-white/5`}>AC</button>
            <button onClick={() => handleOperator('÷')} className={`${buttonClass} ${operator === '÷' ? 'bg-white/30' : ''}`}>÷</button>
            <button onClick={() => handleOperator('×')} className={`${buttonClass} ${operator === '×' ? 'bg-white/30' : ''}`}>×</button>
            
            {['7', '8', '9'].map(num => (
              <button key={num} onClick={() => handleNumber(num)} className={buttonClass}>{num}</button>
            ))}
            <button onClick={() => handleOperator('-')} className={`${buttonClass} ${operator === '-' ? 'bg-white/30' : ''}`}>-</button>
            
            {['4', '5', '6'].map(num => (
              <button key={num} onClick={() => handleNumber(num)} className={buttonClass}>{num}</button>
            ))}
            <button onClick={() => handleOperator('+')} className={`${buttonClass} ${operator === '+' ? 'bg-white/30' : ''}`}>+</button>
            
            {['1', '2', '3'].map(num => (
              <button key={num} onClick={() => handleNumber(num)} className={buttonClass}>{num}</button>
            ))}
            <button onClick={handleEqual} className={`${buttonClass} bg-violet-500 hover:bg-violet-600 row-span-2`}>=</button>
            
            <button onClick={() => handleNumber('0')} className={`${buttonClass} col-span-2`}>0</button>
            <button onClick={() => handleNumber('.')} className={buttonClass}>.</button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
