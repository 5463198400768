import React from 'react';
import { Link } from 'react-router-dom';
import { Heart } from 'lucide-react';

export default function FootnotesSection() {
  return (
    <div className="relative bg-transparent">
      {/* Background with matching gradient and pattern */}
      <div className="absolute inset-0 bg-gradient-to-bl from-purple-600 via-indigo-600 to-blue-600 dark:from-purple-800 dark:via-indigo-800 dark:to-blue-800">
        <div className="absolute inset-0 opacity-10 bg-[radial-gradient(#ffffff_1px,transparent_1px)] [background-size:16px_16px]" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-white">
          {/* About */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">About</h3>
            <p className="text-sm opacity-80">
              Calculato.co offers free, modern calculators for every need, from basic tools to advanced AI-powered solutions.
              <br />
              <br />
              Our mission is to redefine how calculators are built—making them smarter, faster, and more accessible for everyone.
            </p>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Quick Links</h3>
            <ul className="space-y-2 text-sm opacity-80">
              <li>
                <Link to="/financial-calculators" className="hover:text-blue-200 transition-colors">Financial Calculators</Link>
              </li>
              <li>
                <Link to="/health-calculators" className="hover:text-blue-200 transition-colors">Health & Fitness Calculators</Link>
              </li>
              <li>
                <Link to="/academic-calculators" className="hover:text-blue-200 transition-colors">Academic Calculators</Link>
              </li>
              <li>
                <Link to="/lifestyle-calculators" className="hover:text-blue-200 transition-colors">Lifestyle Calculators</Link>
              </li>
              <li>
                <Link to="/advanced-calculators" className="hover:text-blue-200 transition-colors">Advanced Calculators</Link>
              </li>
              <li>
                <Link to="/utility-calculators" className="hover:text-blue-200 transition-colors">Utility Calculators</Link>
              </li>
              <li>
                <Link to="/categories" className="hover:text-blue-200 transition-colors">All Categories</Link>
              </li>
            </ul>
          </div>

          {/* Popular Calculators */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Popular Calculators</h3>
            <ul className="space-y-2 text-sm opacity-80">
              <li>
                <a href="https://calculato.co/mortgage-calculator" className="hover:text-blue-200 transition-colors">Mortgage Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/compound-interest-calculator" className="hover:text-blue-200 transition-colors">Compound Interest</a>
              </li>
              <li>
                <a href="https://calculato.co/percentage-calculator" className="hover:text-blue-200 transition-colors">Percentage Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/bmi-calculator" className="hover:text-blue-200 transition-colors">BMI Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/age-calculator" className="hover:text-blue-200 transition-colors">Age Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/time-calculator" className="hover:text-blue-200 transition-colors">Time Calculator</a>
              </li>
              <li>
                <Link to="/calculators" className="hover:text-blue-200 transition-colors">All Calculators</Link>
              </li>
            </ul>
          </div>

          {/* Financial Tools */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Financial Tools</h3>
            <ul className="space-y-2 text-sm opacity-80">
              <li>
                <a href="https://calculato.co/investment-calculator" className="hover:text-blue-200 transition-colors">Investment Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/paycheck-calculator" className="hover:text-blue-200 transition-colors">Paycheck Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/amortization-calculator" className="hover:text-blue-200 transition-colors">Amortization Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/tax-calculator" className="hover:text-blue-200 transition-colors">Tax Calculator</a>
              </li>
              <li>
                <a href="https://calculato.co/retirement-calculator" className="hover:text-blue-200 transition-colors">Retirement Calculator</a>
              </li>
              <li>
                <Link to="/loan-calculator" className="hover:text-blue-200 transition-colors">Loan Calculator</Link>
              </li>
              <li>
                <Link to="/savings-calculator" className="hover:text-blue-200 transition-colors">Savings Calculator</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-white/10 text-center">
          <div className="flex items-center justify-center space-x-4 text-sm text-white/60 mb-4">
            <Link to="/about" className="hover:text-white/80">About Us</Link>
            <span>|</span>
            <Link to="/contact" className="hover:text-white/80">Contact</Link>
            <span>|</span>
            <Link to="/terms-of-use" className="hover:text-white/80">Terms of Use</Link>
            <span>|</span>
            <Link to="/privacy-policy" className="hover:text-white/80">Privacy Policy</Link>
          </div>
          <div className="text-sm text-white/60">
            @ 2024 Calculato.co. All rights reserved.
            <br />
            <br />
            <p className="text-xs mt-2">
              [ Disclaimer ]
              <br />
              Calculato.co makes no warranty regarding the accuracy, reliability, or suitability of any calculation results or information provided through the platform. Calculato Ltd. shall not be held responsible for any direct or indirect damages that may arise from the use of calculation results or information provided through the platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}