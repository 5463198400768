import { useState, useEffect, useContext, Suspense } from 'react';
import { AlignLeft, SearchIcon, Calculator, X, Sun, Moon } from 'lucide-react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { ThemeContext } from '../App';
import Sidebar from './Sidebar';
import SearchBar from './SearchBar';
import ScrollButton from './ScrollButton';
import { useWidgetStore } from '../lib/store';
import Widget from './CalculatorWidget/Widget';

export default function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const location = useLocation();
  const { setActiveCalculator } = useWidgetStore();

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    document.documentElement.style.scrollBehavior = 'auto';
    
    const mainContent = document.querySelector('main');
    if (mainContent) {
      mainContent.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
    
    return () => {
      document.documentElement.style.scrollBehavior = '';
    };
  }, [location.pathname]);

  // Update active calculator based on route
  useEffect(() => {
    const path = location.pathname;
    const routePath = path.split('/').pop();
    const calculatorId = routePath && routePath !== '' 
      ? routePath // Remove '-calculator' suffix
      : 'bmi-calculator'; // Default to BMI calculator
    setActiveCalculator(calculatorId);
  }, [location.pathname, setActiveCalculator]);

  return (
    <div className={`flex h-screen overflow-hidden ${isDarkMode ? 'dark' : ''} bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800`}>
      {/* Mobile Search Overlay */}
      {isMobileSearchOpen && (
        <div className="fixed inset-0 z-50 lg:hidden">
          <div 
            className="absolute inset-0 bg-gray-900/50 backdrop-blur-sm" 
            onClick={() => setIsMobileSearchOpen(false)}
            role="button"
            tabIndex={0}
            aria-label="Close search overlay"
            onKeyDown={(e) => e.key === 'Enter' && setIsMobileSearchOpen(false)}
          />
          <div className="relative w-full bg-white dark:bg-gray-900">
            <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
              <SearchIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
              <SearchBar 
                autoFocus
                onSelect={() => setIsMobileSearchOpen(false)}
                className="flex-1 mx-4"
              />
              <button
                onClick={() => setIsMobileSearchOpen(false)}
                className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                aria-label="Close search"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Overlay */}
      {isSidebarOpen && window.innerWidth < 1024 && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 dark:bg-opacity-70 transition-opacity lg:hidden z-20"
          onClick={() => setIsSidebarOpen(false)}
          role="button"
          tabIndex={0}
          aria-label="Close sidebar overlay"
          onKeyDown={(e) => e.key === 'Enter' && setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div 
        className={`fixed top-0 left-0 h-full z-40 transition-all duration-300 ease-in-out 
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'} 
          ${isSidebarOpen ? 'lg:w-64' : 'lg:w-20'}
          w-64 lg:block
        `}
      >
        <Sidebar 
          isSidebarOpen={isSidebarOpen}
          onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          onClose={() => setIsSidebarOpen(false)}
        />
      </div>
      
      {/* Main Content */}
      <div className={`flex-1 flex flex-col min-w-0 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'lg:ml-64' : 'lg:ml-20'} ml-0 pt-[4.8rem]`}>
        {/* Top Navigation */}
        <header className="fixed top-0 right-0 left-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-xl z-30 border-b border-gray-200 dark:border-gray-700">
          <div className="h-[4.8rem] relative lg:flex">
            {/* Mobile Header */}
            <div className="lg:hidden grid grid-cols-3 items-center h-full px-4">
              {/* Left Section */}
              <div className="flex items-center">
                <button
                  onClick={() => setIsSidebarOpen(true)}
                  className="text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
                  aria-label="Open menu"
                >
                  <AlignLeft className="h-6 w-6 stroke-[1.5]" />
                </button>
              </div>
              
              {/* Center Section - Logo */}
              <div className="flex justify-center">
                <Link 
                  to="/" 
                  className="flex items-center space-x-2"
                  aria-label="Go to Calculato home page"
                >
                  <div className="p-1.5 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg">
                    <Calculator className="h-5 w-5 text-white" aria-hidden="true" />
                  </div>
                  <span className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">Calculato</span>
                </Link>
              </div>

              {/* Right Section - Theme Toggle & Search */}
              <div className="flex items-center justify-end space-x-[1px]">
                <button
                  onClick={toggleDarkMode}
                  className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
                  aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
                >
                  {isDarkMode ? (
                    <Sun className="h-6 w-6 stroke-[1.5]" />
                  ) : (
                    <Moon className="h-6 w-6 stroke-[1.5]" />
                  )}
                </button>

                <button
                  onClick={() => setIsMobileSearchOpen(true)}
                  className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors duration-200"
                  aria-label="Open search"
                >
                  <SearchIcon className="h-6 w-6 stroke-[1.5]" />
                </button>
              </div>
            </div>

            {/* Desktop Header */}
            <div className={`hidden lg:flex items-center justify-between h-full transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-20'} flex-1`}>
              <div className="flex items-center max-w-xl px-4">
                <SearchBar className="w-[400px]" />
              </div>

              <div className="flex items-center space-x-4 px-4">
                {/* Theme Toggle Button */}
                <button
                  onClick={toggleDarkMode}
                  className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 bg-gray-100 dark:bg-gray-800 rounded-lg transition-colors duration-200"
                  aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
                >
                  {isDarkMode ? (
                    <Sun className="h-5 w-5" />
                  ) : (
                    <Moon className="h-5 w-5" />
                  )}
                </button>
                
                {/* Sign In Button */}
                <button 
                  className="px-5 py-2.5 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg font-medium hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 overflow-y-auto bg-gray-50 dark:bg-gray-900">
          <div className="min-h-screen">
            <div>
              <Suspense fallback={
                <div className="flex items-center justify-center min-h-screen">
                  <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                </div>
              }>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </main>
        <ScrollButton />
        <Widget />
      </div>
    </div>
  );
}