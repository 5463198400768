import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import calculator translations
import amortizationEN from "../translations/calculators/amortization/en.json";
import amortizationPTBR from "../translations/calculators/amortization/pt-BR.json";
import compoundInterestEN from "../translations/calculators/compound-interest/en.json";
import compoundInterestPTBR from "../translations/calculators/compound-interest/pt-BR.json";
import mortgageEN from "../translations/calculators/mortgage/en.json";
import loanEN from "../translations/calculators/loan/en.json";
import bmiEN from "../translations/calculators/bmi/en.json";
import calorieEN from "../translations/calculators/calorie/en.json";
import percentageEN from "../translations/calculators/percentage/en.json";
import scientificEN from "../translations/calculators/scientific/en.json";
import ageEN from "../translations/calculators/age/en.json";
import timeEN from "../translations/calculators/time/en.json";

// Import dashboard section translations
import dashboardHeroEN from "../translations/dashboard/hero/en.json";
import dashboardTrustEN from "../translations/dashboard/trust/en.json";
import dashboardFeaturesEN from "../translations/dashboard/features/en.json";
import dashboardFootnotesEN from "../translations/dashboard/footnotes/en.json";

import dashboardHeroPTBR from "../translations/dashboard/hero/pt-BR.json";
import dashboardTrustPTBR from "../translations/dashboard/trust/pt-BR.json";
import dashboardFeaturesPTBR from "../translations/dashboard/features/pt-BR.json";
import dashboardFootnotesPTBR from "../translations/dashboard/footnotes/pt-BR.json";

// Import category translations
import financialCategoryEN from "../translations/categories/financial/en.json";
import healthCategoryEN from "../translations/categories/health/en.json";
import utilityCategoryEN from "../translations/categories/utility/en.json";

import financialCategoryPTBR from "../translations/categories/financial/pt-BR.json";
import healthCategoryPTBR from "../translations/categories/health/pt-BR.json";
import utilityCategoryPTBR from "../translations/categories/utility/pt-BR.json";

const resources = {
  en: {
    translation: {
      calculators: {
        "amortization": amortizationEN,
        "compound-interest": compoundInterestEN,
        "mortgage": mortgageEN,
        "loan": loanEN,
        "bmi": bmiEN,
        "calorie": calorieEN,
        "percentage": percentageEN,
        "scientific": scientificEN,
        "age": ageEN,
        "time": timeEN
      },
      categories: {
        "financial": financialCategoryEN,
        "health": healthCategoryEN,
        "utility": utilityCategoryEN
      },
      dashboard: {
        hero: dashboardHeroEN,
        trust: dashboardTrustEN,
        features: dashboardFeaturesEN,
        footnotes: dashboardFootnotesEN
      }
    }
  },
  "pt-BR": {
    translation: {
      calculators: {
        "amortization": amortizationPTBR,
        "compound-interest": compoundInterestPTBR
      },
      categories: {
        "financial": financialCategoryPTBR,
        "health": healthCategoryPTBR,
        "utility": utilityCategoryPTBR
      },
      dashboard: {
        hero: dashboardHeroPTBR,
        trust: dashboardTrustPTBR,
        features: dashboardFeaturesPTBR,
        footnotes: dashboardFootnotesPTBR
      }
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    defaultNS: "translation",
    supportedLngs: ["en", "pt-BR", "pt"],
    load: 'all',
    cleanCode: true,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['path', 'cookie', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      caches: ['cookie'],
      excludeCacheFor: ['en']
    },
    debug: true,
    returnObjects: true,
    keySeparator: '.',
    nsSeparator: ':',
  });

export default i18n;