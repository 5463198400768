import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search, 
  X, 
  ArrowRight, 
  DollarSign, 
  Heart, 
  GraduationCap, 
  Coffee, 
  Zap 
} from 'lucide-react';

interface SearchBarProps {
  autoFocus?: boolean;
  onSelect?: () => void;
  className?: string;
}

const calculators = [
  // Financial Calculators
  {
    id: 'basic',
    title: 'Basic Calculator',
    category: 'Financial',
    path: '/tools/basic-calculator'
  },
  {
    id: 'mortgage',
    title: 'Mortgage Calculator',
    category: 'Financial',
    path: '/tools/mortgage-calculator'
  },
  {
    id: 'loan',
    title: 'Loan Calculator',
    category: 'Financial',
    path: '/tools/loan-calculator'
  },
  {
    id: 'compound',
    title: 'Compound Interest',
    category: 'Financial',
    path: '/tools/compound-interest-calculator'
  },
  {
    id: 'auto',
    title: 'Auto Loan',
    category: 'Financial',
    path: '/tools/auto-loan-calculator'
  },
  {
    id: 'tax',
    title: 'Tax Calculator',
    category: 'Financial',
    path: '/tools/tax-calculator'
  },
  {
    id: 'retirement',
    title: 'Retirement Calculator',
    category: 'Financial',
    path: '/tools/retirement-calculator'
  },
  {
    id: 'savings',
    title: 'Savings Calculator',
    category: 'Advanced',
    path: '/tools/savings-calculator'
  },
  {
    id: 'amortization',
    title: 'Amortization Calculator',
    category: 'Financial',
    path: '/tools/amortization-calculator'
  },

  // Health Calculators
  {
    id: 'bmi',
    title: 'BMI Calculator',
    category: 'Health',
    path: '/tools/bmi-calculator'
  },
  {
    id: 'tdee',
    title: 'TDEE Calculator',
    category: 'Health',
    path: '/tools/tdee-calculator'
  },
  {
    id: 'bmr',
    title: 'BMR Calculator',
    category: 'Health',
    path: '/tools/bmr-calculator'
  },
  {
    id: 'bodyFat',
    title: 'Body Fat Calculator',
    category: 'Health',
    path: '/tools/body-fat-calculator'
  },
  {
    id: 'calorie',
    title: 'Calorie Calculator',
    category: 'Health',
    path: '/tools/calorie-calculator'
  },
  {
    id: 'pregnancy',
    title: 'Pregnancy Calculator',
    category: 'Health',
    path: '/tools/pregnancy-calculator'
  },
  {
    id: 'ovulation',
    title: 'Ovulation Calculator',
    category: 'Health',
    path: '/tools/ovulation-calculator'
  },
  {
    id: 'calorieDeficit',
    title: 'Calorie Deficit Calculator',
    category: 'Health',
    path: '/tools/calorie-deficit-calculator'
  },
  {
    id: 'protein',
    title: 'Protein Calculator',
    category: 'Health',
    path: '/tools/protein-calculator'
  },
  {
    id: 'pace',
    title: 'Pace Calculator',
    category: 'Health',
    path: '/tools/pace-calculator'
  },
 

  // Academic Calculators
  {
    id: 'scientific',
    title: 'Scientific Calculator',
    category: 'Academic',
    path: '/tools/scientific-calculator'
  },
  {
    id: 'algebra',
    title: 'Algebra Calculator',
    category: 'Academic',
    path: '/tools/algebra-calculator'
  },
  {
    id: 'integral',
    title: 'Integral Calculator',
    category: 'Academic',
    path: '/tools/integral-calculator'
  },
  {
    id: 'grade',
    title: 'Grade Calculator',
    category: 'Academic',
    path: '/tools/grade-calculator'
  },
  {
    id: 'gpa',
    title: 'GPA Calculator',
    category: 'Academic',
    path: '/tools/gpa-calculator'
  },
  {
    id: 'fraction',
    title: 'Fraction Calculator',
    category: 'Academic',
    path: '/tools/fraction-calculator'
  },
  {
    id: 'standardDeviation',
    title: 'Standard Deviation Calculator',
    category: 'Academic',
    path: '/tools/standard-deviation-calculator'
  },
  {
    id: 'matrix',
    title: 'Matrix Calculator',
    category: 'Academic',
    path: '/tools/matrix-calculator'
  },
  {
    id: 'quadratic',
    title: 'Quadratic Calculator',
    category: 'Academic',
    path: '/tools/quadratic-calculator'
  },
  {
    id: 'derivative',
    title: 'Derivative Calculator',
    category: 'Academic',
    path: '/tools/derivative-calculator'
  },


  // Advanced Calculators
  {
    id: 'graphing',
    title: 'Graphing Calculator',
    category: 'Advanced',
    path: '/tools/graphing-calculator'
  },
  {
    id: 'concrete',
    title: 'Concrete Calculator',
    category: 'Advanced',
    path: '/tools/concrete-calculator'
  },
  {
    id: 'investment',
    title: 'Investment Calculator',
    category: 'Advanced',
    path: '/tools/investment-calculator'
  },

  // Lifestyle Calculators
  {
    id: 'percentage',
    title: 'Percentage Calculator',
    category: 'Lifestyle',
    path: '/tools/percentage-calculator'
  },
  {
    id: 'time',
    title: 'Time Calculator',
    category: 'Lifestyle',
    path: '/tools/time-calculator'
  },
  {
    id: 'tip',
    title: 'Tip Calculator',
    category: 'Lifestyle',
    path: '/tools/tip-calculator'
  },
  {
    id: 'age',
    title: 'Age Calculator',
    category: 'Lifestyle',
    path: '/tools/age-calculator'
  },
  {
    id: 'date',
    title: 'Date Calculator',
    category: 'Lifestyle',
    path: '/tools/date-calculator'
  },
  {
    id: 'sleep',
    title: 'Sleep Calculator',
    category: 'Lifestyle',
    path: '/tools/sleep-calculator'
  },
  {
    id: 'paycheck',
    title: 'Paycheck Calculator',
    category: 'Financial',
    path: '/tools/paycheck-calculator'
  },
  {
    id: 'rounding',
    title: 'Rounding Calculator',
    category: 'Academic',
    path: '/tools/rounding-calculator'
  },
  {
    id: 'squareFootage',
    title: 'Square Footage Calculator',
    category: 'Lifestyle',
    path: '/tools/square-footage-calculator'
  }
];

const categories = [
  { 
    id: 'financial', 
    name: 'Financial Calculators', 
    path: '/financial-calculators',
    icon: DollarSign,
    color: 'text-blue-500'
  },
  { 
    id: 'health', 
    name: 'Health Calculators', 
    path: '/health-calculators',
    icon: Heart,
    color: 'text-rose-500'
  },
  { 
    id: 'academic', 
    name: 'Academic Calculators', 
    path: '/academic-calculators',
    icon: GraduationCap,
    color: 'text-emerald-500'
  },
  { 
    id: 'lifestyle', 
    name: 'Lifestyle Calculators', 
    path: '/lifestyle-calculators',
    icon: Coffee,
    color: 'text-amber-500'
  },
  { 
    id: 'advanced', 
    name: 'Advanced Calculators', 
    path: '/advanced-calculators',
    icon: Zap,
    color: 'text-purple-500'
  }
];

export default function SearchBar({ autoFocus = false, onSelect, className = '' }: SearchBarProps) {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCalculators = query
    ? calculators.filter(calc =>
        calc.title.toLowerCase().includes(query.toLowerCase()) ||
        calc.category.toLowerCase().includes(query.toLowerCase())
      )
    : [];

  const handleSelect = (path: string) => {
    navigate(path);
    setQuery('');
    setIsOpen(false);
    onSelect?.();
  };

  return (
    <div ref={searchRef} className={`relative ${className}`}>
      <div className="relative">
        <div className="rounded-2xl p-[0.15rem] bg-gradient-to-r from-purple-500 to-pink-500">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setIsOpen(true)}
            className="w-full pl-10 pr-4 py-2.5 text-black bg-white rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-200"
            placeholder="Search calculators..."
            autoFocus={autoFocus}
          />
        </div>
        <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400 dark:text-gray-500" />
        {query && (
          <button
            onClick={() => setQuery('')}
            className="absolute right-3 top-3 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 transition-colors duration-200"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>

      {isOpen && (
        <div className="absolute mt-2 w-full bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden z-50 transition-all duration-200 transform">
          {query ? (
            <div className="p-2">
              {filteredCalculators.length > 0 ? (
                filteredCalculators.map((calc) => (
                  <button
                    key={calc.id}
                    onClick={() => handleSelect(calc.path)}
                    className="w-full text-left px-4 py-2.5 text-sm hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200 group"
                  >
                    <div className="font-medium text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400">{calc.title}</div>
                    <div className="text-xs text-gray-500 dark:text-gray-400">{calc.category}</div>
                  </button>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                  No calculators found
                </div>
              )}
            </div>
          ) : (
            <div className="p-2 space-y-1">
              {categories.map((category) => {
                const Icon = category.icon;
                return (
                  <button
                    key={category.id}
                    onClick={() => handleSelect(category.path)}
                    className="w-full flex items-center justify-between px-4 py-3 text-sm hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-all duration-200 group"
                  >
                    <div className="flex items-center space-x-3">
                      <Icon className={`h-5 w-5 ${category.color}`} />
                      <span className="font-medium text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        {category.name}
                      </span>
                    </div>
                    <ArrowRight className="h-4 w-4 text-gray-400 dark:text-gray-500 group-hover:text-blue-600 dark:group-hover:text-blue-400 transform group-hover:translate-x-1 transition-all duration-200" />
                  </button>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}