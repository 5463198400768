import React, { useState, useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import EmbedLayout from './components/EmbedLayout';
import { LanguageProvider } from './components/LanguageProvider';
import { SpeedInsights } from "@vercel/speed-insights/react";
import ScrollToTop from './components/ScrollToTop';
import './i18n/i18n';
import { WidgetProvider } from './components/CalculatorWidget/WidgetProvider';
import HomePage from './pages/Home';
import LanguageRedirect from './components/LanguageRedirect';
import ConsentPopup from './components/ConsentPopup';

// Lazy load all calculator components
const FinancialCalculators = React.lazy(() => import('./pages/FinancialCalculators'));
const HealthCalculators = React.lazy(() => import('./pages/HealthCalculators'));
const AcademicCalculators = React.lazy(() => import('./pages/AcademicCalculators'));
const LifestyleCalculators = React.lazy(() => import('./pages/LifestyleCalculators'));
const AdvancedCalculators = React.lazy(() => import('./pages/AdvancedCalculators'));
const UtilityCalculators = React.lazy(() => import('./pages/UtilityCalculators'));
const Calculators = React.lazy(() => import('./pages/calculators'));
const InvestmentCalculator = React.lazy(() => import('./components/calculators/InvestmentCalculator'));
const About = React.lazy(() => import('./pages/About'));
const AIFitnessCalculator = React.lazy(() => import('./components/calculators/AIFitnessCalculator'));
const LifeExpectancyCalculator = React.lazy(() => import('./components/calculators/LifeExpectancyCalculator'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const Contact = React.lazy(() => import('./pages/Contact'));
const BasicCalculator = React.lazy(() => import('./components/calculators/BasicCalculator'));
const MortgageCalculator = React.lazy(() => import('./components/calculators/MortgageCalculator'));
const LoanCalculator = React.lazy(() => import('./components/calculators/LoanCalculator'));
const BMICalculator = React.lazy(() => import('./components/calculators/BMICalculator'));
const TDEECalculator = React.lazy(() => import('./components/calculators/TDEECalculator'));
const ScientificCalculator = React.lazy(() => import('./components/calculators/ScientificCalculator'));
const AlgebraCalculator = React.lazy(() => import('./components/calculators/AlgebraCalculator'));
const IntegralCalculator = React.lazy(() => import('./components/calculators/IntegralCalculator'));
const GradeCalculator = React.lazy(() => import('./components/calculators/GradeCalculator'));
const GPACalculator = React.lazy(() => import('./components/calculators/GPACalculator'));
const FractionCalculator = React.lazy(() => import('./components/calculators/FractionCalculator'));
const PercentageCalculator = React.lazy(() => import('./components/calculators/PercentageCalculator'));
const TimeCalculator = React.lazy(() => import('./components/calculators/TimeCalculator'));
const TipCalculator = React.lazy(() => import('./components/calculators/TipCalculator'));
const AgeCalculator = React.lazy(() => import('./components/calculators/AgeCalculator'));
const GraphingCalculator = React.lazy(() => import('./components/calculators/GraphingCalculator'));
const StandardDeviationCalculator = React.lazy(() => import('./components/calculators/StandardDeviationCalculator'));
const MatrixCalculator = React.lazy(() => import('./components/calculators/MatrixCalculator'));
const QuadraticCalculator = React.lazy(() => import('./components/calculators/QuadraticCalculator'));
const CalorieDeficitCalculator = React.lazy(() => import('./components/calculators/CalorieDeficitCalculator'));
const DateCalculator = React.lazy(() => import('./components/calculators/DateCalculator'));
const PaycheckCalculator = React.lazy(() => import('./components/calculators/PaycheckCalculator'));
const CompoundInterestCalculator = React.lazy(() => import('./components/calculators/CompoundInterestCalculator'));
const AutoLoanCalculator = React.lazy(() => import('./components/calculators/AutoLoanCalculator'));
const TaxCalculator = React.lazy(() => import('./components/calculators/TaxCalculator'));
const RetirementCalculator = React.lazy(() => import('./components/calculators/RetirementCalculator'));
const SavingsCalculator = React.lazy(() => import('./components/calculators/SavingsCalculator'));
const AmortizationCalculator = React.lazy(() => import('./components/calculators/AmortizationCalculator'));
const BMRCalculator = React.lazy(() => import('./components/calculators/BMRCalculator'));
const BodyFatCalculator = React.lazy(() => import('./components/calculators/BodyFatCalculator'));
const CalorieCalculator = React.lazy(() => import('./components/calculators/CalorieCalculator'));
const PregnancyCalculator = React.lazy(() => import('./components/calculators/PregnancyCalculator'));
const OvulationCalculator = React.lazy(() => import('./components/calculators/OvulationCalculator'));
const ProteinCalculator = React.lazy(() => import('./components/calculators/ProteinCalculator'));
const PaceCalculator = React.lazy(() => import('./components/calculators/PaceCalculator'));
const DerivativeCalculator = React.lazy(() => import('./components/calculators/DerivativeCalculator'));
const RoundingCalculator = React.lazy(() => import('./components/calculators/RoundingCalculator'));
const SquareFootageCalculator = React.lazy(() => import('./components/calculators/SquareFootageCalculator'));
const SleepCalculator = React.lazy(() => import('./components/calculators/SleepCalculator'));
const ConcreteCalculator = React.lazy(() => import('./components/calculators/ConcreteCalculator'));

const Categories = React.lazy(() => import('./pages/categories'));

export const ThemeContext = React.createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('darkMode', isDarkMode.toString());
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  // Create a dedicated helmetContext to prevent conflicts
  const helmetContext = {};

  return (
    <div>
      <ConsentPopup />
      <HelmetProvider context={helmetContext}>
        <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
          <WidgetProvider>
            <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
              <ScrollToTop />
              <Routes>
                {/* Embed Routes */}
                <Route path="/embed/*" element={
                  <div className={`${isDarkMode ? 'dark' : ''}`}>
                    <EmbedLayout>
                      <Outlet />
                    </EmbedLayout>
                  </div>
                }>
                  <Route path="ai-fitness" element={<AIFitnessCalculator />} />
                  <Route path="basic" element={<BasicCalculator />} />
                  <Route path="mortgage" element={<MortgageCalculator />} />
                  <Route path="loan" element={<LoanCalculator />} />
                  <Route path="bmi" element={<BMICalculator />} />
                  <Route path="tdee" element={<TDEECalculator />} />
                  <Route path="scientific" element={<ScientificCalculator />} />
                  <Route path="algebra" element={<AlgebraCalculator />} />
                  <Route path="integral" element={<IntegralCalculator />} />
                  <Route path="grade" element={<GradeCalculator />} />
                  <Route path="gpa" element={<GPACalculator />} />
                  <Route path="fraction" element={<FractionCalculator />} />
                  <Route path="percentage" element={<PercentageCalculator />} />
                  <Route path="time" element={<TimeCalculator />} />
                  <Route path="tip" element={<TipCalculator />} />
                  <Route path="age" element={<AgeCalculator />} />
                  <Route path="graphing" element={<GraphingCalculator />} />
                  <Route path="standard-deviation" element={<StandardDeviationCalculator />} />
                  <Route path="matrix" element={<MatrixCalculator />} />
                  <Route path="quadratic" element={<QuadraticCalculator />} />
                  <Route path="date" element={<DateCalculator />} />
                  <Route path="paycheck" element={<PaycheckCalculator />} />
                  <Route path="compound-interest" element={<CompoundInterestCalculator />} />
                  <Route path="auto-loan" element={<AutoLoanCalculator />} />
                  <Route path="tax" element={<TaxCalculator />} />
                  <Route path="retirement" element={<RetirementCalculator />} />
                  <Route path="square-footage" element={<SquareFootageCalculator />} />
                  <Route path="derivative" element={<DerivativeCalculator />} />
                  <Route path="sleep" element={<SleepCalculator />} />
                  <Route path="concrete" element={<ConcreteCalculator />} />
                  <Route path="investment" element={<InvestmentCalculator />} />
                  <Route path="calorie" element={<CalorieCalculator />} />
                  <Route path="bmr" element={<BMRCalculator />} />
                  <Route path="body-fat" element={<BodyFatCalculator />} />
                  <Route path="pregnancy" element={<PregnancyCalculator />} />
                  <Route path="ovulation" element={<OvulationCalculator />} />
                  <Route path="amortization" element={<AmortizationCalculator />} />
                  <Route path="savings" element={<SavingsCalculator />} />
                  <Route path="calorie-deficit" element={<CalorieDeficitCalculator />} />
                  <Route path="protein" element={<ProteinCalculator />} />
                  <Route path="pace" element={<PaceCalculator />} />
                </Route>

                {/* Language-prefixed routes (for non-English) */}
                <Route path="/:lang/*" element={
                  <LanguageProvider>
                    <div className={`${isDarkMode ? 'dark' : ''}`}>
                      <Layout>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Outlet />
                        </Suspense>
                      </Layout>
                    </div>
                  </LanguageProvider>
                }>
                  <Route index element={<HomePage />} />
                  <Route path="about" element={<About />} />
                  <Route path="calculators" element={<Calculators />} />
                  <Route path="financial-calculators" element={<FinancialCalculators />} />
                  <Route path="health-calculators" element={<HealthCalculators />} />
                  <Route path="academic-calculators" element={<AcademicCalculators />} />
                  <Route path="lifestyle-calculators" element={<LifestyleCalculators />} />
                  <Route path="advanced-calculators" element={<AdvancedCalculators />} />
                  <Route path="utility-calculators" element={<UtilityCalculators />} />
                  <Route path="basic-calculator" element={<BasicCalculator />} />
                  <Route path="mortgage-calculator" element={<MortgageCalculator />} />
                  <Route path="loan-calculator" element={<LoanCalculator />} />
                  <Route path="bmi-calculator" element={<BMICalculator />} />
                  <Route path="tdee-calculator" element={<TDEECalculator />} />
                  <Route path="scientific-calculator" element={<ScientificCalculator />} />
                  <Route path="algebra-calculator" element={<AlgebraCalculator />} />
                  <Route path="integral-calculator" element={<IntegralCalculator />} />
                  <Route path="grade-calculator" element={<GradeCalculator />} />
                  <Route path="gpa-calculator" element={<GPACalculator />} />
                  <Route path="fraction-calculator" element={<FractionCalculator />} />
                  <Route path="percentage-calculator" element={<PercentageCalculator />} />
                  <Route path="time-calculator" element={<TimeCalculator />} />
                  <Route path="tip-calculator" element={<TipCalculator />} />
                  <Route path="age-calculator" element={<AgeCalculator />} />
                  <Route path="graphing-calculator" element={<GraphingCalculator />} />
                  <Route path="standard-deviation-calculator" element={<StandardDeviationCalculator />} />
                  <Route path="matrix-calculator" element={<MatrixCalculator />} />
                  <Route path="quadratic-calculator" element={<QuadraticCalculator />} />
                  <Route path="date-calculator" element={<DateCalculator />} />
                  <Route path="paycheck-calculator" element={<PaycheckCalculator />} />
                  <Route path="compound-interest-calculator" element={<CompoundInterestCalculator />} />
                  <Route path="auto-loan-calculator" element={<AutoLoanCalculator />} />
                  <Route path="tax-calculator" element={<TaxCalculator />} />
                  <Route path="retirement-calculator" element={<RetirementCalculator />} />
                  <Route path="savings-calculator" element={<SavingsCalculator />} />
                  <Route path="amortization-calculator" element={<AmortizationCalculator />} />
                  <Route path="bmr-calculator" element={<BMRCalculator />} />
                  <Route path="body-fat-calculator" element={<BodyFatCalculator />} />
                  <Route path="calorie-calculator" element={<CalorieCalculator />} />
                  <Route path="pregnancy-calculator" element={<PregnancyCalculator />} />
                  <Route path="ovulation-calculator" element={<OvulationCalculator />} />
                  <Route path="calorie-deficit-calculator" element={<CalorieDeficitCalculator />} />
                  <Route path="protein-calculator" element={<ProteinCalculator />} />
                  <Route path="pace-calculator" element={<PaceCalculator />} />
                  <Route path="derivative-calculator" element={<DerivativeCalculator />} />
                  <Route path="rounding-calculator" element={<RoundingCalculator />} />
                  <Route path="sleep-calculator" element={<SleepCalculator />} />
                  <Route path="square-footage-calculator" element={<SquareFootageCalculator />} />
                  <Route path="concrete-calculator" element={<ConcreteCalculator />} />
                  <Route path="investment-calculator" element={<InvestmentCalculator />} />
                  <Route path="life-expectancy-calculator" element={<LifeExpectancyCalculator />} />
                </Route>

                {/* Default routes (English, no prefix) */}
                <Route element={
                  <LanguageProvider>
                    <div className={`${isDarkMode ? 'dark' : ''}`}>
                      <Layout>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Outlet />
                        </Suspense>
                      </Layout>
                    </div>
                  </LanguageProvider>
                }>
                  <Route index element={<HomePage />} />
                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="terms-of-use" element={<TermsOfUse />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="calculators" element={<Calculators />} />
                  <Route path="financial-calculators" element={<FinancialCalculators />} />
                  <Route path="health-calculators" element={<HealthCalculators />} />
                  <Route path="academic-calculators" element={<AcademicCalculators />} />
                  <Route path="lifestyle-calculators" element={<LifestyleCalculators />} />
                  <Route path="advanced-calculators" element={<AdvancedCalculators />} />
                  <Route path="utility-calculators" element={<UtilityCalculators />} />
                  
                  {/* Calculator Routes */}
                  <Route path="basic-calculator" element={<BasicCalculator />} />
                  <Route path="mortgage-calculator" element={<MortgageCalculator />} />
                  <Route path="loan-calculator" element={<LoanCalculator />} />
                  <Route path="bmi-calculator" element={<BMICalculator />} />
                  <Route path="tdee-calculator" element={<TDEECalculator />} />
                  <Route path="scientific-calculator" element={<ScientificCalculator />} />
                  <Route path="algebra-calculator" element={<AlgebraCalculator />} />
                  <Route path="integral-calculator" element={<IntegralCalculator />} />
                  <Route path="grade-calculator" element={<GradeCalculator />} />
                  <Route path="gpa-calculator" element={<GPACalculator />} />
                  <Route path="fraction-calculator" element={<FractionCalculator />} />
                  <Route path="percentage-calculator" element={<PercentageCalculator />} />
                  <Route path="time-calculator" element={<TimeCalculator />} />
                  <Route path="tip-calculator" element={<TipCalculator />} />
                  <Route path="age-calculator" element={<AgeCalculator />} />
                  <Route path="graphing-calculator" element={<GraphingCalculator />} />
                  <Route path="standard-deviation-calculator" element={<StandardDeviationCalculator />} />
                  <Route path="matrix-calculator" element={<MatrixCalculator />} />
                  <Route path="quadratic-calculator" element={<QuadraticCalculator />} />
                  <Route path="date-calculator" element={<DateCalculator />} />
                  <Route path="paycheck-calculator" element={<PaycheckCalculator />} />
                  <Route path="compound-interest-calculator" element={<CompoundInterestCalculator />} />
                  <Route path="auto-loan-calculator" element={<AutoLoanCalculator />} />
                  <Route path="tax-calculator" element={<TaxCalculator />} />
                  <Route path="retirement-calculator" element={<RetirementCalculator />} />
                  <Route path="savings-calculator" element={<SavingsCalculator />} />
                  <Route path="amortization-calculator" element={<AmortizationCalculator />} />
                  <Route path="bmr-calculator" element={<BMRCalculator />} />
                  <Route path="body-fat-calculator" element={<BodyFatCalculator />} />
                  <Route path="calorie-calculator" element={<CalorieCalculator />} />
                  <Route path="pregnancy-calculator" element={<PregnancyCalculator />} />
                  <Route path="ovulation-calculator" element={<OvulationCalculator />} />
                  <Route path="calorie-deficit-calculator" element={<CalorieDeficitCalculator />} />
                  <Route path="protein-calculator" element={<ProteinCalculator />} />
                  <Route path="pace-calculator" element={<PaceCalculator />} />
                  <Route path="derivative-calculator" element={<DerivativeCalculator />} />
                  <Route path="rounding-calculator" element={<RoundingCalculator />} />
                  <Route path="sleep-calculator" element={<SleepCalculator />} />
                  <Route path="square-footage-calculator" element={<SquareFootageCalculator />} />
                  <Route path="concrete-calculator" element={<ConcreteCalculator />} />
                  <Route path="investment-calculator" element={<InvestmentCalculator />} />
                  <Route path="/ai-fitness-calculator" element={<AIFitnessCalculator />} />
                  <Route path="/life-expectancy-calculator" element={<LifeExpectancyCalculator />} />
                  <Route path="categories" element={<Categories />} />
                </Route>

                {/* Catch all unknown routes */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          </WidgetProvider>
        </ThemeContext.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;