import { Link } from 'react-router-dom';
import { 
  DollarSign, 
  Heart, 
  GraduationCap, 
  Coffee, 
  Zap,
  FileText
} from 'lucide-react';

const categories = [
  {
    id: 'financial',
    name: 'Financial Calculators',
    description: 'All your financial calculations in one place',
    icon: DollarSign,
    theme: 'from-blue-500 to-indigo-500',
    href: '/financial-calculators'
  },
  {
    id: 'health',
    name: 'Health Calculators',
    description: 'Track and monitor your health metrics',
    icon: Heart,
    theme: 'from-pink-500 to-rose-500',
    href: '/health-calculators'
  },
  {
    id: 'academic',
    name: 'Academic Calculators',
    description: 'Essential calculations for students',
    icon: GraduationCap,
    theme: 'from-purple-500 to-indigo-500',
    href: '/academic-calculators'
  },
  {
    id: 'lifestyle',
    name: 'Lifestyle Calculators',
    description: 'Everyday calculations made simple',
    icon: Coffee,
    theme: 'from-orange-500 to-amber-500',
    href: '/lifestyle-calculators'
  },
  {
    id: 'advanced',
    name: 'Advanced Calculators',
    description: 'Complex calculations for professionals',
    icon: Zap,
    theme: 'from-emerald-500 to-teal-500',
    href: '/advanced-calculators'
  },
  {
    id: 'utility',
    name: 'Utility Calculators',
    description: 'Additional helpful calculations',
    icon: FileText,
    theme: 'from-cyan-500 to-blue-500',
    href: '/utility-calculators'
  }
];

export default function CategoryGrid() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="sr-only">Calculator Categories</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {categories.map((category) => {
          const Icon = category.icon;
          return (
            <Link
              key={category.id}
              to={category.href}
              className="group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700"
            >
              <div className={`absolute top-0 left-0 w-full h-1 bg-gradient-to-r ${category.theme}`} />
              <div className={`inline-flex p-3 rounded-xl bg-gradient-to-br ${category.theme} transform group-hover:scale-110 transition-transform duration-200`}>
                <Icon className="h-6 w-6 text-white" />
              </div>
              <h2 className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-100">{category.name}</h2>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{category.description}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}