import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';
import {
  DollarSign,
  Heart,
  GraduationCap,
  Coffee,
  Zap,
  ChevronDown,
  Sun,
  Moon,
  Calculator,
  ArrowLeft,
  ArrowRight,
  // Financial icons
  Home,
  PiggyBank,
  Percent,
  Car,
  Receipt,
  Wallet,
  LineChart,
  // Health icons
  Scale,
  Utensils,
  Activity,
  Heart as HeartIcon,
  Dumbbell,
  Baby,
  Calendar,
  TrendingDown,
  Beef,
  Timer,
  // Academic icons
  BookOpen,
  XSquare,
  Grid,
  Sigma,
  // Lifestyle icons
  Clock,
  Ruler,
  // Advanced icons
  Building2,
  TrendingUp,
  Building,
  ArrowLeftRight,
  Divide,
  // Utility icons
  Hash,
  Shuffle,
  Key,
} from 'lucide-react';

interface MenuItem {
  title: string;
  icon: React.ElementType;
  theme: string;
  path: string;
  items: {
    name: string;
    path: string;
    icon: React.ElementType;
  }[];
}

interface SidebarProps {
  onClose?: () => void;
  isSidebarOpen?: boolean;
  onToggleSidebar?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, onToggleSidebar, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCategory, setOpenCategory] = useState<string | null>('Financial');
  const { isDarkMode } = useContext(ThemeContext);

  // Rest of your menuItems array and functions remain exactly the same...
  // (I'm keeping all the menu items exactly as they were in your original code)

  const menuItems: MenuItem[] = [
    {
      title: 'Financial',
      icon: DollarSign,
      theme: 'from-blue-500 to-indigo-500',
      path: '/financial-calculators',
      items: [
        { name: 'Mortgage Calculator', path: '/mortgage-calculator', icon: Home },
        { name: 'Loan Calculator', path: '/loan-calculator', icon: Calculator },
        { name: 'Compound Interest', path: '/compound-interest-calculator', icon: Percent },
        { name: 'Auto Loan', path: '/auto-loan-calculator', icon: Car },
        { name: 'Tax Calculator', path: '/tax-calculator', icon: Receipt },
        { name: 'Retirement Calculator', path: '/retirement-calculator', icon: Wallet },
        { name: 'Savings Calculator', path: '/savings-calculator', icon: PiggyBank },
        { name: 'Amortization Calculator', path: '/amortization-calculator', icon: LineChart },
        { name: 'Paycheck Calculator', path: '/paycheck-calculator', icon: Wallet },
        { name: 'Investment Calculator', path: '/investment-calculator', icon: TrendingUp }
      ]
    },
    {
      title: 'Health',
      icon: Heart,
      theme: 'from-pink-500 to-rose-500',
      path: '/health-calculators',
      items: [
        { name: 'AI Fitness Calculator', path: '/ai-fitness-calculator', icon: Dumbbell },
        { name: 'BMI Calculator', path: '/bmi-calculator', icon: Scale },
        { name: 'Calorie Calculator', path: '/calorie-calculator', icon: Utensils },
        { name: 'TDEE Calculator', path: '/tdee-calculator', icon: Activity },
        { name: 'BMR Calculator', path: '/bmr-calculator', icon: HeartIcon },
        { name: 'Body Fat Calculator', path: '/body-fat-calculator', icon: Dumbbell },
        { name: 'Pregnancy Calculator', path: '/pregnancy-calculator', icon: Baby },
        { name: 'Ovulation Calculator', path: '/ovulation-calculator', icon: Calendar },
        { name: 'Calorie Deficit Calculator', path: '/calorie-deficit-calculator', icon: TrendingDown },
        { name: 'Protein Calculator', path: '/protein-calculator', icon: Beef },
        { name: 'Pace Calculator', path: '/pace-calculator', icon: Timer },
        { name: 'Life Expectancy Calculator', path: '/life-expectancy-calculator', icon: HeartIcon },
      ]
    },
    {
      title: 'Academic',
      icon: GraduationCap,
      theme: 'from-purple-500 to-indigo-500',
      path: '/academic-calculators',
      items: [
        { name: 'Basic Calculator', path: '/basic-calculator', icon: Calculator },
        { name: 'Rounding Calculator', path: '/rounding-calculator', icon: Hash },
        { name: 'GPA Calculator', path: '/gpa-calculator', icon: Calculator },
        { name: 'Grade Calculator', path: '/grade-calculator', icon: BookOpen },
        { name: 'Scientific Calculator', path: '/scientific-calculator', icon: Hash },
        { name: 'Algebra Calculator', path: '/algebra-calculator', icon: XSquare },
        { name: 'Derivative Calculator', path: '/derivative-calculator', icon: Hash },
        { name: 'Fraction Calculator', path: '/fraction-calculator', icon: Divide },
        { name: 'Standard Deviation Calculator', path: '/standard-deviation-calculator', icon: Divide },
        { name: 'Integral Calculator', path: '/integral-calculator', icon: Sigma },
        { name: 'Quadratic Calculator', path: '/quadratic-calculator', icon: Calculator },
        { name: 'Matrix Calculator', path: '/matrix-calculator', icon: Grid }
      ]
    },
    {
      title: 'Lifestyle',
      icon: Calendar,
      theme: 'from-orange-500 to-yellow-500',
      path: '/lifestyle-calculators',
      items: [
        { name: 'Age Calculator', path: '/age-calculator', icon: Baby },
        { name: 'Time Calculator', path: '/time-calculator', icon: Clock },
        { name: 'Tip Calculator', path: '/tip-calculator', icon: Calculator },
        { name: 'Date Calculator', path: '/date-calculator', icon: Calendar },
        { name: 'Percentage Calculator', path: '/percentage-calculator', icon: Percent },
        { name: 'Sleep Calculator', path: '/sleep-calculator', icon: Moon },
        { name: 'Square Footage Calculator', path: '/square-footage-calculator', icon: Ruler }
      ]
    },
    {
      title: 'Advanced',
      icon: Zap,
      theme: 'from-emerald-500 to-teal-500',
      path: '/advanced-calculators',
      items: [
        { name: 'Graphing Calculator', path: '/graphing-calculator', icon: LineChart },
        { name: 'Concrete Calculator', path: '/concrete-calculator', icon: Building2 },
        { name: 'Mortgage Payoff Calculator', path: '/mortgage-payoff-calculator', icon: Home },
        { name: 'Construction Calculator', path: '/construction-calculator', icon: Ruler },
        { name: 'Conversion Calculator', path: '/conversion-calculator', icon: ArrowLeftRight },
        { name: 'Engineering Calculator', path: '/engineering-calculator', icon: Grid },
        { name: 'Cost of Living Calculator', path: '/cost-of-living-calculator', icon: DollarSign }
      ]
    },
    {
      title: 'Utility',
      icon: Calculator,
      theme: 'from-cyan-500 to-blue-500',
      path: '/utility-calculators',
      items: [
        { name: 'Hash Generator', path: '/hash-generator', icon: Hash },
        { name: 'Random Generator', path: '/random-generator', icon: Shuffle },
        { name: 'Password Generator', path: '/password-generator', icon: Key },
      ]
    }
  ];

  const toggleCategory = (category: string) => {
    if (isSidebarOpen) {
      setOpenCategory(openCategory === category ? null : category);
    } else {
      // When sidebar is collapsed, clicking an icon navigates to the category page
      const menuItem = menuItems.find(item => item.title === category);
      if (menuItem) {
        navigate(menuItem.path);
      }
    }
  };

  useEffect(() => {
    // Find which category the current path belongs to
    const currentPath = location.pathname;
    const category = menuItems.find(item => 
      currentPath === item.path || item.items.some(subItem => subItem.path === currentPath)
    );
    if (category) {
      setOpenCategory(category.title);
    }
  }, [location.pathname]);

  const isActiveCategory = (category: MenuItem) => {
    return location.pathname === category.path || 
           category.items.some(item => item.path === location.pathname);
  };

  return (
    <div className="w-full h-screen bg-white/80 dark:bg-gray-900/80 backdrop-blur-xl border-r border-gray-200 dark:border-gray-700 flex flex-col">
      <div className={`p-4 border-b border-gray-200 dark:border-gray-700 ${!isSidebarOpen ? 'px-3' : ''}`}>
        <div className="flex items-center justify-between">
          <Link 
            to="/" 
            className={`flex items-center ${!isSidebarOpen ? 'pl-1' : ''}`}
            aria-label="Go to Calculato home page"
          >
            <div className={`p-2 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-xl ${!isSidebarOpen ? 'mx-auto' : ''}`}>
              <Calculator className="h-7 w-7 text-white" aria-hidden="true" />
            </div>
            {isSidebarOpen && (
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                Calculato
              </span>
            )}
          </Link>
          {onToggleSidebar && isSidebarOpen && (
            <div className="absolute top-20 -right-3">
              <button
                onClick={onToggleSidebar}
                className="p-1.5 bg-white dark:bg-gray-800 rounded-full shadow-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                aria-label={`${isSidebarOpen ? 'Collapse' : 'Expand'} sidebar navigation`}
                aria-expanded={isSidebarOpen}
              >
                {isSidebarOpen ? (
                  <ArrowLeft className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
                ) : (
                  <ArrowRight className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <nav className={`p-4 space-y-1 ${!isSidebarOpen ? 'px-2' : ''}`}>
          {menuItems.map((category) => (
            <div key={category.title}>
              <button
                onClick={() => toggleCategory(category.title)}
                className={`flex items-center justify-between w-full rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800 group transition-colors ${
                  isSidebarOpen ? 'px-3 py-2' : 'p-2'
                } ${isActiveCategory(category) ? 'bg-gray-50 dark:bg-gray-800' : ''}`}
                aria-label={`${category.title} calculators section`}
                aria-expanded={openCategory === category.title}
                aria-controls={`${category.title.toLowerCase()}-menu`}
                id={`${category.title.toLowerCase()}-button`}
              >
                <div className="flex items-center">
                  <div className={`p-3 rounded-xl bg-gradient-to-r ${category.theme} transform group-hover:scale-110 transition-transform duration-200 ${!isSidebarOpen ? 'mx-auto' : ''}`}>
                    <category.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </div>
                  {isSidebarOpen && (
                    <span className={`ml-3 text-sm font-medium ${
                      isActiveCategory(category) ? 'text-blue-600 dark:text-blue-400' : 'text-gray-600 dark:text-gray-400'
                    }`}>
                      {category.title}
                    </span>
                  )}
                </div>
                {isSidebarOpen && (
                  <ChevronDown
                    className={`h-4 w-4 text-gray-400 dark:text-gray-600 transform transition-transform duration-200 ${
                      openCategory === category.title ? 'rotate-180' : ''
                    }`}
                    aria-hidden="true"
                  />
                )}
              </button>
              {openCategory === category.title && isSidebarOpen && (
                <div
                  id={`${category.title.toLowerCase()}-menu`}
                  role="region"
                  aria-labelledby={`${category.title.toLowerCase()}-button`}
                  className={`mt-2 space-y-1`}
                >
                  {category.items.map((item) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      onClick={() => {
                        if (window.innerWidth < 1024 && onClose) {
                          onClose();
                        }
                      }}
                      className={`flex items-center rounded-md px-3 py-2 text-sm hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors ${
                        location.pathname === item.path
                          ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20'
                          : 'text-gray-700 dark:text-gray-300'
                      }`}
                    >
                      <item.icon className="h-4 w-4 mr-2" />
                      {item.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>

      {!isSidebarOpen && (
        <div className="absolute top-20 -right-3">
          <button
            onClick={onToggleSidebar}
            className="p-1.5 bg-white dark:bg-gray-800 rounded-full shadow-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
            aria-label={`${isSidebarOpen ? 'Collapse' : 'Expand'} sidebar navigation`}
            aria-expanded={isSidebarOpen}
          >
            <ArrowRight className="h-4 w-4 text-gray-600 dark:text-gray-400" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;