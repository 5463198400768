import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <header 
      className="relative overflow-hidden -mt-4"
      role="banner"
      aria-label={t('dashboard.hero.heading.main')}
    >
      <Helmet>
        <title>Calculato.co | Free Online Calculators for Every Need</title>
      </Helmet>

      {/* Background Effects */}
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 dark:from-blue-950 dark:via-indigo-950 dark:to-purple-950" />
        <div className="absolute inset-0 opacity-10 bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] dark:bg-[radial-gradient(#4a5568_1px,transparent_1px)] [background-size:16px_16px]" />
      </div>

      {/* Floating Elements */}
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        <div className="absolute -left-4 top-1/4 w-24 h-24 bg-blue-500/10 dark:bg-blue-400/10 rounded-full blur-2xl animate-pulse" />
        <div className="absolute right-1/4 top-1/3 w-32 h-32 bg-purple-500/10 dark:bg-purple-400/10 rounded-full blur-2xl animate-pulse delay-1000" />
        <div className="absolute left-1/3 bottom-1/4 w-28 h-28 bg-indigo-500/10 dark:bg-indigo-400/10 rounded-full blur-2xl animate-pulse delay-2000" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center space-y-16">
          <div className="space-y-2 animate-fade-in">
            <h1 
              className="text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight"
              aria-label={t('dashboard.hero.heading.main')}
            >
              <span className="inline-block bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 dark:from-blue-400 dark:via-indigo-400 dark:to-purple-400 pb-2 animate-gradient">
                {t('dashboard.hero.heading.main')}
              </span>
              <br />
              <span className="relative inline-block text-gray-900 dark:text-gray-100 mt-2 animate-slide-up">
                {t('dashboard.hero.heading.sub')}
                <div className="absolute -bottom-2 left-0 right-0 h-1 bg-gradient-to-r from-blue-600 to-indigo-600 transform origin-left animate-scale-x"></div>
              </span>
            </h1>
          </div>

          <div 
            className="animate-float-up space-y-4"
            role="contentinfo"
            aria-label={t('dashboard.hero.description')}
          >
            <h2 className="text-base md:text-lg lg:text-xl font-bold text-gray-900 dark:text-gray-100 animate-gradient-slow max-w-4xl mx-auto leading-relaxed">
              {t('dashboard.hero.description')}.
            </h2>
          </div>

          <div 
            className="flex justify-center space-x-4 animate-bounce-in"
            role="list"
            aria-label={t('dashboard.hero.features.title')}
          >
            <div 
              className="inline-flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400"
              role="listitem"
            >
              <span className="inline-block w-2 h-2 bg-green-500 rounded-full animate-pulse" aria-hidden="true"></span>
              <span>{t('dashboard.hero.features.calculators')}</span>
            </div>
            <div 
              className="inline-flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400"
              role="listitem"
            >
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full animate-pulse" aria-hidden="true"></span>
              <span>{t('dashboard.hero.features.results')}</span>
            </div>
            <div 
              className="inline-flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400"
              role="listitem"
            >
              <span className="inline-block w-2 h-2 bg-purple-500 rounded-full animate-pulse" aria-hidden="true"></span>
              <span>{t('dashboard.hero.features.free')}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Gradient */}
      <div 
        className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-gray-50 to-transparent dark:from-gray-900 dark:to-transparent"
        aria-hidden="true"
      />
    </header>
  );
}